import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProductModel } from '../models/product.model';
import { OrderDetailsModel } from '../models/order.model';
import { PaginationModel } from '../models/pagination.model';
import { PagedProduct } from '../models/paged-product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  baseUrl = environment.apiUrl;
  private orderItemSubscriscriotion = new Subject<OrderDetailsModel>();
  private selectedProductSubscription = new Subject<ProductModel>();
  private selectedImages = new BehaviorSubject<any[]>([]);
  constructor(private http: HttpClient) {}

  addToCart(data: OrderDetailsModel) {
    this.orderItemSubscriscriotion.next(data);
  }

  orderItemListener(): Observable<OrderDetailsModel> {
    return this.orderItemSubscriscriotion.asObservable();
  }

  emitSelectedProduct(data: ProductModel) {
    this.selectedProductSubscription.next(data);
  }

  listenSelectedProduct(): Observable<ProductModel> {
    return this.selectedProductSubscription.asObservable();
  }

  getProductTypes(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/ProductType/GetAll`);
  }

  getProducts(
    entityId: number,
    outLetId: number,
    activeStatus: boolean,
    categoryId: number,
    fromPrice: number,
    toPrice: number,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    let parameter = new HttpParams();
    parameter = parameter.set('entityId', entityId.toString());
    parameter = parameter.set('activeStatus', activeStatus.toString());
    if (outLetId > 0) {
      parameter = parameter.set('outLetId', outLetId.toString());
    }
    if (categoryId != null) {
      parameter = parameter.set('productTypeId', categoryId.toString());
    }
    if (fromPrice != null) {
      parameter = parameter.set('fromPrice', fromPrice.toString());
    }
    if (toPrice != null) {
      parameter = parameter.set('toPrice', toPrice.toString());
    }
    if (fromDate != null) {
      parameter = parameter.set('fromDate', fromDate.toString());
    }
    if (toDate != null) {
      parameter = parameter.set('toDate', toDate.toString());
    }

    return this.http.get<any>(`${this.baseUrl}/api/Product/Paged`, {
      params: parameter,
    });
  }

  getProductsByCategory(categoryId: number, outletId: number): Observable<any> {
    return this.http
      .get<any>(
        `${this.baseUrl}/api/Product/GetByCategory/${categoryId}/${outletId}`
      )
      .pipe(map((response) => response));
  }

  addProduct(product: ProductModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/Product`, product);
  }

  getWeightList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/Product/GetWeightList`);
  }

  getFoodTypeList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/Product/GetFoodTypeList`);
  }

  statusDataUpdate(
    productIdList: Array<number>,
    status: boolean
  ): Observable<any> {
    const payload = {
      systemIdList: productIdList,
      status: status,
    };
    return this.http.put<any>(
      `${this.baseUrl}/api/Product/updateProductStatus`,
      payload
    );
  }

  listBySearch(formData: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/api/Product/ListBySearch`,
      formData
    );
  }

  getProductById(productId: number): Observable<ProductModel> {
    return this.http.get<any>(`${this.baseUrl}/api/Product/${productId}`).pipe(
      map((response: any) => {
        let productModel: ProductModel = response;
        productModel.defaultImgStr = `${environment.imgUrl}${response.defaultImgStr}`;
        productModel.productMasterId = response.productMasterId;
        return productModel;
      })
    );
  }

  getProductByPIdNOutletId(
    productId: number,
    outletId: number
  ): Observable<ProductModel> {
    return this.http
      .get<any>(`${this.baseUrl}/api/Product/${productId}/${outletId}`)
      .pipe(
        map((response: any) => {
          response.productAddons.forEach((addon: any) => {
            addon.quantity = 1;
            addon.sellPrice = addon.productVariants[0].sellPrice;
            addon.addOnId = addon.productVariants[0].variantId;
            addon.productMasterId = addon.productVariants[0].productMasterId;
            //   console.log('addon from service mapping', addon);
          });

          let productModel: ProductModel = response;

          productModel.defaultImgStr = `${environment.imgUrl}${response.defaultImgStr}`;
          productModel.productMasterId = response.productMasterId;

          return productModel;
        })
      );
  }

  updateProduct(productModel: ProductModel): Observable<any> {
    console.log('SERVICE', productModel);
    return this.http.post<any>(
      `${this.baseUrl}/api/Product/UpdateAsync`,
      productModel
    );
  }

  getEntityChildProductTypes(entityChildId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/ProductType/GetEntityChildProductType/${entityChildId}`
    );
  }

  deleteProduct(productMasterId: number): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/api/Product/DeleteProduct?productMasterId=${productMasterId}`,
      {}
    );
  }

  getProductByOutletForNewPos(
    outletId: number,
    orderChanelNumber: number
  ): Observable<any> {
    //1 = channel
    //0 == payment type
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Product/GetByOutletId/${outletId}/${orderChanelNumber}/0`
    );
  }

  getOutletProductsForOrder(
    outletId: number,
    orderChanelNumber: number
  ): Observable<any> {
    //1 = channel
    //0 == payment type
    return this.http.get<any[]>(
      `${this.baseUrl}/api/Product/GetOutletProductsForOrder/${outletId}/1/${orderChanelNumber}/0`
    );
  }

  getProductByOutlet(
    outletId: number,
    orderChanelNumber: number
  ): Observable<any> {
    //1 = channel
    //0 == payment type

    return this.http.get<any>(
      `${this.baseUrl}/api/Product/GetByOutletId/${outletId}/${orderChanelNumber}/0`
    );
  }

  pagedProducts(paginationModel: PagedProduct): Observable<any> {
    let parameter = new HttpParams();
    parameter = parameter.set('entityId', paginationModel.entityId.toString());

    parameter = parameter.set(
      'pageNumber',
      paginationModel.pageNumber.toString()
    );
    parameter = parameter.set(
      'pageLength',
      paginationModel.pageLength.toString()
    );

    parameter = parameter.set(
      'activeStatus',
      (paginationModel.activeStatus ?? '').toString()
    );

    if (paginationModel.productTypeId != null) {
      parameter = parameter.set(
        'productTypeId',
        paginationModel.productTypeId.toString()
      );
    }
    if (paginationModel.searchString != null) {
      parameter = parameter.set(
        'searchString',
        paginationModel.searchString.toString()
      );
    }

    if (paginationModel.productName != null) {
      parameter = parameter.set(
        'productName',
        paginationModel.productName.toString()
      );
    }

    if (paginationModel.outletId != null) {
      parameter = parameter.set(
        'outletId',
        paginationModel.outletId.toString()
      );
    }

    if (paginationModel.isOwner != null) {
      parameter = parameter.set('isOwner', paginationModel.isOwner.toString());
    }

    return this.http.get<any>(`${this.baseUrl}/api/Product/Paged`, {
      params: parameter,
    });
  }

  getProductListForDropdown(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/Product/GetProductListForDropDown`
    );
  }

  getimageLibrary(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/Product/GetImageLibrary`);
  }

  setSelectedImages(images: any[]) {
    this.selectedImages.next(images);
    console.log('Service Selected Image:', images);
  }

  getSelectedImages() {
    return this.selectedImages.asObservable();
  }

  clearSelectedImages(): void {
    this.selectedImages.next([]);
  }

  getMultipleOutletsProductCategories(outletIds: any): Observable<any> {
    return this.http.post<any[]>(
      `${this.baseUrl}/api/ProductType/getMultipleOutletsProductCategories`,
      outletIds
    );
  }

  getOutletWiseProductforDiscountDropdown(outletIds: any): Observable<any> {
    return this.http.post<any[]>(
      `${this.baseUrl}/api/Product/GetOutletWiseProductforDiscountDropdown`,
      outletIds
    );
  }

  private entityProductCodeSource = new BehaviorSubject<string>(''); // Default value is empty
  nextProductCode$ = this.entityProductCodeSource.asObservable();

  setEntityProductCode(code: string): void {
    this.entityProductCodeSource.next(code); // Update the product code
  }
  getEntityProductCode(): string {
    return this.entityProductCodeSource.value; // Get the current product code
  }
}
